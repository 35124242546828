import type { EnvVars } from '@/lib/partners/public/vars/types/EnvVars';
import { getPartnerSettings } from '../../partners/settings/getSettings';

export const getEnvVarByPartner = (
  envVar: string,
  partner: string,
  publicVars: EnvVars,
): string | undefined => {
  let regionalEnvVar = envVar;
  const settings: any = getPartnerSettings(partner ?? '');
  // Feature flags will need to change if appcs is made region specific
  const regionSpecificEnvVars: string[] = [
    'AUTH0_CLIENT_ID',
    'AUTH0_CLIENT_SECRET',
    'AUTH0_HOST',
    'LEAD_SERVICE_DB_ENDPOINT',
    'LEAD_SERVICE_DB_KEY',
    'AZURE_API_MANAGEMENT_BASE_URL',
    'AZURE_API_MANAGEMENT_LIBERIS_CREATE_SUBSCRIPTION_KEY',
    'AZURE_API_MANAGEMENT_EBAY_PROXY_SUBSCRIPTION_KEY',
    'AZURE_API_MANAGEMENT_CORE_ACCOUNT_SERVICE_SUBSCRIPTION_KEY',
    'AZURE_API_MANAGEMENT_CODAT_SERVICE_SUBSCRIPTION_KEY',
    'AZURE_API_MANAGEMENT_CONTRACTS_SUBSCRIPTION_KEY',
    'AZURE_API_MANAGEMENT_APPLICATION_SERVICE_SUBSCRIPTION_KEY',
    'ACTIVE_CUSTOMER_AUTH0_CLIENT_SECRET',
    'ACTIVE_CUSTOMER_AUTH0_SECRET',
    'AZURE_API_MANAGEMENT_STATEMENT_SERVICE_SUBSCRIPTION_KEY',
    'AZURE_API_MANAGEMENT_STATEMENT_SERVICE_BASE_URL'
  ];

  if (settings.dataRegion && regionSpecificEnvVars.includes(envVar)) {
    regionalEnvVar = `${regionalEnvVar}_${settings.dataRegion}`;
  }

  let envVarValue: string | undefined;

  if (publicVars.hasOwnProperty(regionalEnvVar)) {
    envVarValue = publicVars[regionalEnvVar];
  } else {
    envVarValue = process?.env?.[regionalEnvVar];
  }

  return envVarValue;
};
