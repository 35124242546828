'use client';

import {
  BusinessFormDetails,
  OwnershipFormDetails,
  OffersResponse,
  ReviewFormDetails,
} from '@/app/contexts/global/actions';
import persistenceService from '@/lib/utils/persistenceService';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { ActionType, STORAGE_KEY } from './actions';
import { ActionUpdateOffersResponse } from './reducer';

export type Action = any;
//{
//   type: Partial<ActionType & ActionUpdateOffersResponse>;
//   payload: Partial<
//     BusinessFormDetails &
//       OwnershipFormDetails &
//       OffersResponse &
//       ReviewFormDetails &
//       ActionUpdateOffersResponse
//   >;
// };
export type State = any;

const setState: Dispatch<SetStateAction<Record<string, unknown>>> = () => {};
export const initialState: State = persistenceService.get(STORAGE_KEY)
  ? persistenceService.get(STORAGE_KEY)
  : {};

const GlobalStoreStateContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: setState,
});

export const useGlobalStore = () => useContext(GlobalStoreStateContext);

export default GlobalStoreStateContext;
